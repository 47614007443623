import { useState, useEffect } from 'react';

const pollenLevel = [
	{
		level: 0,
		name: 'Inga halter',
	},
	{
		level: 1,
		name: 'Låga',
	},
	{
		level: 2,
		name: 'Låga till måttliga',
	},
	{
		level: 3,
		name: 'Måttliga',
	},
	{
		level: 4,
		name: 'Måttliga till höga',
	},
	{
		level: 5,
		name: 'Höga',
	},
	{
		level: 6,
		name: 'Höga till mycket höga',
	},
	{
		level: 7,
		name: 'Mycket höga',
	},
];

const pollenItems = [
	{
		id: '2a2a2a2a-2a2a-4a2a-aa2a-2a313a323236',
		name: 'Al',
	},
	{
		id: '2a2a2a2a-2a2a-4a2a-aa2a-2a313a323533',
		name: 'Malörtsambrosia',
	},
	{
		id: '2a2a2a2a-2a2a-4a2a-aa2a-2a313a323530',
		name: 'Gråbo',
	},
	{
		id: '2a2a2a2a-2a2a-4a2a-aa2a-2a313a323332',
		name: 'Björk',
	},
	{
		id: '2a2a2a2a-2a2a-4a2a-aa2a-2a313a323233',
		name: 'Hassel',
	},
	{
		id: '2a2a2a2a-2a2a-4a2a-aa2a-2a313a323335',
		name: 'Bok',
	},
	{
		id: '2a2a2a2a-2a2a-4a2a-aa2a-2a313a323433',
		name: 'Gräs',
	},
	{
		id: '2a2a2a2a-2a2a-4a2a-aa2a-2a313a323337',
		name: 'Ek',
	},
	{
		id: '2a2a2a2a-2a2a-4a2a-aa2a-2a313a323330',
		name: 'Sälg och viden',
	},
	{
		id: '2a2a2a2a-2a2a-4a2a-aa2a-2a313a323331',
		name: 'Alm',
	},
];

//TODO this should be dynamically fetched and cached
const regions = [
	{
		id: '2a2a2a2a-2a2a-4a2a-aa2a-2a2a2a303a32',
		name: 'Stockholm',
	},
	{
		id: '2a2a2a2a-2a2a-4a2a-aa2a-2a2a303a3234',
		name: 'Göteborg',
	},
	{
		id: '2a2a2a2a-2a2a-4a2a-aa2a-2a2a2a303a39',
		name: 'Malmö',
	},
	{
		id: '2a2a2a2a-2a2a-4a2a-aa2a-2a2a2a303a33',
		name: 'Borlänge',
	},
	{
		id: '2a2a2a2a-2a2a-4a2a-aa2a-2a2a2a303a34',
		name: 'Forshaga',
	},
	{
		id: '2a2a2a2a-2a2a-4a2a-aa2a-2a2a2a303a35',
		name: 'Gävle',
	},
	{
		id: '2a2a2a2a-2a2a-4a2a-aa2a-2a2a2a303a36',
		name: 'Jönköping',
	},
	{
		id: '2a2a2a2a-2a2a-4a2a-aa2a-2a2a2a303a37',
		name: 'Visby',
	},
	{
		id: '2a2a2a2a-2a2a-4a2a-aa2a-2a2a303a3131',
		name: 'Bräkne-Hoby',
	},
	{
		id: '2a2a2a2a-2a2a-4a2a-aa2a-2a2a303a3130',
		name: 'Hässleholm',
	},
	{
		id: '2a2a2a2a-2a2a-4a2a-aa2a-2a2a303a3132',
		name: 'Kristianstad',
	},
	{
		id: '2a2a2a2a-2a2a-4a2a-aa2a-2a2a303a3133',
		name: 'Nässjö',
	},
	{
		id: '2a2a2a2a-2a2a-4a2a-aa2a-2a2a303a3134',
		name: 'Eskilstuna',
	},
	{
		id: '2a2a2a2a-2a2a-4a2a-aa2a-2a2a303a3135',
		name: 'Norrköping',
	},
	{
		id: '2a2a2a2a-2a2a-4a2a-aa2a-2a2a303a3136',
		name: 'Skövde',
	},
	{
		id: '2a2a2a2a-2a2a-4a2a-aa2a-2a2a303a3137',
		name: 'Sundsvall',
	},
	{
		id: '2a2a2a2a-2a2a-4a2a-aa2a-2a2a303a3138',
		name: 'Umeå',
	},
	{
		id: '2a2a2a2a-2a2a-4a2a-aa2a-2a2a303a3139',
		name: 'Västervik',
	},
	{
		id: '2a2a2a2a-2a2a-4a2a-aa2a-2a2a303a3230',
		name: 'Östersund',
	},
	{
		id: '2a2a2a2a-2a2a-4a2a-aa2a-2a2a303a3231',
		name: 'Piteå',
	},
	{
		id: '2a2a2a2a-2a2a-4a2a-aa2a-2a2a303a3232',
		name: 'Kiruna',
	},
	{
		id: '2a2a2a2a-2a2a-4a2a-aa2a-2a2a303a3233',
		name: 'Ljusdal',
	},
];

const getPollenNameById = (id) => {
	return pollenItems.find((item) => item.id === id).name;
};
const getHumanReadablePollenLevel = (level) => {
	return pollenLevel.find((item) => item.level === level).name;
};

const extractAvaialbleDates = (data) => {
	if (!data) return [];
	const weekDays = new Set();
	const options = { weekday: 'long' };
	data.forEach((element) => {
		element.levelSeries.forEach((series) => {
			const date = new Date(series.time);
			const day = new Intl.DateTimeFormat('sv-SE', options).format(date);

			if (!weekDays.has(day)) {
				weekDays.add(day);
			}
		});
	});
	return Array.from(weekDays);
};

const usePollenData = ({ load = false }) => {
	const [dataFetched, setDataFetched] = useState(undefined);
	const [dataPending, setDataPending] = useState(undefined);
	const [data, setData] = useState([]);
	const [region, setRegion] = useState('2a2a2a2a-2a2a-4a2a-aa2a-2a2a2a303a32');

	useEffect(() => {
		if (!load) return;
		if (dataPending) return;
		if (dataFetched) return;
		console.log('loading pollen data..	');
		const fetchData = async () => {
			setDataPending(true);
			const result = await fetch(`/api/get-pollen-data?region=${region}`);
			if (result.ok) {
				setData(await result.json());
			}
			setDataFetched(true);
			setTimeout(() => setDataPending(false), 200);
		};
		if (!dataFetched && region) {
			if (dataPending) return;
			fetchData();
		}
	}, [load, data, dataFetched, region, dataPending]);

	return {
		setRegion: (region) => {
			setDataFetched(false);
			setRegion(region);
		},
		data,
		getPollenNameById,
		getHumanReadablePollenLevel,
		weekdays: extractAvaialbleDates(data),
		regions,
		selectedRegion: region,
		pending: dataPending,
	};
};

export default usePollenData;
